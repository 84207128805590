import React, { memo } from 'react';
import useMedia from '~/hooks/useMedia';
import { SignalrTwStockQuote } from '~/modules/SDK/socket/SignalrTwStock50Quote';
import { css } from '@emotion/react';
import { flex, jc, ai, pureGrid } from '~/modules/AppLayout/FlexGridCss';
import { PercentagePalette } from '~/modules/monitors/PercentagePalette';
import { globalGrey, globalYellow } from '~/modules/AppLayout/Colors';
import { useWeightedStocksPoolState } from '~/modules/monitors/useWeightedStocksPoolState';
import { HighlightText } from '~/components/HighlightText';
import { WordBlockSwitch } from '~/components/WordBlockSwitch';
import { useMonitorSelectionStore } from '~/modules/monitors/useMonitorSelectionStore';
import { useSnapshot } from 'valtio';
import { useThemeOfParent } from '~/components/theme/useThemeOfParent';
import CapitalAdsContent from '~/modules/ads/CapitalAdsContent';
import { meCheckHandlerTrialCodeAndProductWeb } from '~/modules/SDK/me/meCheckHandlerTrialCodeAndProductWeb';
const toggleBoxCss = css `
  ${flex.wrap.default};
  ${ai.center};
  gap: 4px;
  color: ${globalGrey.g100};
  margin-right: auto;
`;
export const StockListTile = memo(function StockListTile() {
    const theme = useThemeOfParent().themeKey;
    const legitUser = meCheckHandlerTrialCodeAndProductWeb.useCheck();
    const { isPc, isPhone } = useMedia();
    const { state, acts } = useWeightedStocksPoolState.useContainer();
    const snap = useSnapshot(useMonitorSelectionStore);
    const symbols = state.symbols.map(s => s.symbol).slice(0, snap.symbolCount);
    const boxesCss = css `
    ${flex.wrap.default};
    ${isPc ? jc.flexEnd : jc.flexStart}
    margin: 8px 4px;
    gap: 4px;
    & > * {
      margin-right: 8px;
      margin-bottom: 4px;
    }
  `;
    return (<>
      <div css={boxesCss}>
        {snap.disableSelection ? null : (<div css={toggleBoxCss}>
            <WordBlockSwitch tooltip={'權值股市場類別切換'} title={'市場類別'} labelOff={'上市'} labelOn={'上櫃'} checked={state.isTpex} delegate={acts.setIsTpex} theme={theme}/>
            <WordBlockSwitch tooltip={'以「前一日收盤價格」或「今日開盤」作為計算點'} title={'漲跌比例參考點'} labelOff={'昨收'} labelOn={'開盤'} checked={snap.baseRefIsTodayOpen} delegate={(checked) => {
                useMonitorSelectionStore.baseRefIsTodayOpen = checked;
            }} theme={theme}/>
            <WordBlockSwitch tooltip={'以「前一日收盤價格」或「今日開盤」作為計算點'} title={'貢獻點數參考點'} labelOff={'昨收'} labelOn={'開盤'} checked={snap.baseContributionPointIsToday} delegate={(checked) => {
                useMonitorSelectionStore.baseContributionPointIsToday = checked;
            }} theme={theme}/>
            <WordBlockSwitch tooltip={'即時價格與今日高低價格之相對變化'} title={'強弱度即時監控'} labelOff={'關閉'} labelOn={'開啟'} checked={snap.isRealtimeStatus} delegate={(checked) => {
                useMonitorSelectionStore.isRealtimeStatus = checked;
            }} theme={theme}/>
          </div>)}

        <PercentagePalette />
        <HighlightText background={'white'} color={'black'} content={'$價格'} border={'1px solid black'}/>
        <HighlightText background={globalYellow.y500} color={'black'} content={'貢獻大盤點數'} border={'1px solid black'}/>
      </div>

      {isPc ? (<div css={css `
            ${pureGrid};
            grid-template-rows: repeat(5, 88px);
            grid-template-columns: 14fr 14fr 11fr 11fr 9fr 9fr 8fr 8fr 7fr 7fr;
            ${snap.symbolCount <= 20
                ? `
            grid-template-rows: repeat(4, 88px);
            grid-template-columns: 14fr 11fr 9fr 8fr 7fr;`
                : ``}
            grid-auto-flow: column;
            gap: 4px;
            margin: 8px;
            ${isPhone && 'padding-bottom: 64px;'}
          `}>
          {symbols.map((symbol, idx) => {
                const colSize = Math.max(11 - Math.floor(idx / 5), 6);
                const size = colSize > 9 ? 'large' : colSize > 7 ? 'medium' : 'small';
                return (<span key={symbol}>
                <SignalrTwStockQuote isTpex={state.isTpex} size={size} symbol={symbol}/>
              </span>);
            })}
          {/* 非會員會跳出廣告 */}
          {!legitUser && (<>
              <CapitalAdsContent pageName='momitor' adCategory='capitalAdsWithButton'/>
            </>)}
        </div>) : (<div css={css `
            ${flex.wrap.default};
            ${jc.center}
          `}>
          {symbols.map((symbol, idx) => {
                const colSize = Math.max(11 - Math.floor(idx / 5), 6);
                const size = colSize > 10 ? 'large' : colSize > 5 ? 'medium' : 'small';
                return (<SignalrTwStockQuote isTpex={state.isTpex} key={symbol} size={size} symbol={symbol}/>);
            })}
          {/* 非會員會跳出廣告 */}
          {!legitUser && (<>
              <CapitalAdsContent pageName='momitor' adCategory='capitalAdsWithButton'/>
            </>)}
        </div>)}
    </>);
});
