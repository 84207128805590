import { css } from '@emotion/react';
import React, { memo, useState } from 'react';
import { CapitalAds } from '~/modules/ads/CapitalAds';
import { Dialog } from '@mui/material';
const CapitalAdsContent = memo(function CapitalAdsContent(props) {
    const [userAdsOpen, setUserAdsOpen] = useState(true);
    return (<Dialog onClose={() => setUserAdsOpen(false)} open={userAdsOpen}>
        <CapitalAds pageName={props.pageName} adCategory={props.adCategory}/>
        <img css={css `
            position: absolute;
            top: 0;
            right: 0;
            width: 36px;
            height: 36px;
            cursor: pointer;
          `} src='/futuresai/capitalAds/x-circle.png' alt='close' onClick={() => setUserAdsOpen(false)}/>
      </Dialog>);
});
export default CapitalAdsContent;
